import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';

function* loadSubscription(payload) {
  try {
    yield put(actions.loadSubscriptionApiSuccess(payload.pagination, payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadApiError(error));
  }
}

function* storeSubscriptionApi() {
  try {
    yield put({ type: actions.LOAD_SUBSCRIPTION_LIST });
  } catch (error) {
    console.log(error);
    yield put(actions.saveApiError(error));
  }
}

function* loadExercise(payload) {
  try {
    yield put(actions.loadExerciseApiSuccess(payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadApiError(error));
  }
}

function* storeExerciseApi() {
  try {
    yield put({ type: actions.LOAD_EXERCISE_LIST });
  } catch (error) {
    console.log(error);
    yield put(actions.saveApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_SUBSCRIPTION_LIST, loadSubscription),
    takeEvery(actions.SAVE_SUBSCRIPTION_LIST, storeSubscriptionApi),

    takeEvery(actions.LOAD_EXERCISE_LIST, loadExercise),
    takeEvery(actions.SAVE_EXERCISE_LIST, storeExerciseApi)
  ]);
}
