import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  paymentList: []
};

export default function paymentReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_PAYMENT_LIST:
      return {
        ...state,
        paymentList: action.data
      };
    case actions.LOAD_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        paymentList: action.payload.data,
        pagination: action.payload.pagination,
        errorMessage: false
      };
    default:
      return state;
  }
}
