const actions = {
  LOAD_USER_LIST: 'LOAD_USER_LIST',
  LOAD_USER_LIST_SUCCESS: 'LOAD_USER_LIST_SUCCESS',
  SAVE_USER_LIST: 'SAVE_USER_LIST',
  LOAD_PROFESSION_LIST: 'LOAD_PROFESSION_LIST',
  LOAD_PROFESSION_LIST_SUCCESS: 'LOAD_PROFESSION_LIST_SUCCESS',
  SAVE_PROFESSION_LIST: 'SAVE_PROFESSION_LIST',
  LOAD_EXERCISE_LIST: 'LOAD_EXERCISE_LIST',
  LOAD_EXERCISE_LIST_SUCCESS: 'LOAD_EXERCISE_LIST_SUCCESS',
  SAVE_EXERCISE_LIST: 'SAVE_EXERCISE_LIST',

  LOAD_API_ERROR: 'LOAD_API_ERROR',
  SAVE_API_ERROR: 'SAVE_API_ERROR',

  TOGGLE_LOADING: 'TOGGLE_LOADING',

  toggleLoading: (value) => ({ type: actions.TOGGLE_LOADING, payload: { value } }),

  loadUserApi: (pagination, data) => {
    return { type: actions.LOAD_USER_LIST, pagination, data };
  },
  loadUserApiSuccess: (pagination, data) => ({
    type: actions.LOAD_USER_LIST_SUCCESS,
    payload: { pagination, data }
  }),
  loadProfessionApi: (data) => {
    return { type: actions.LOAD_PROFESSION_LIST, data };
  },
  loadProfessionApiSuccess: (data) => ({
    type: actions.LOAD_PROFESSION_LIST_SUCCESS,
    payload: { data }
  }),
  loadUserExerciseApi: (pagination, data) => {
    return { type: actions.LOAD_EXERCISE_LIST, pagination, data };
  },
  loadUserExerciseApiSuccess: (pagination, data) => ({
    type: actions.LOAD_EXERCISE_LIST_SUCCESS,
    payload: { pagination, data }
  }),
  loadApiError: (error) => ({
    type: actions.LOAD_API_ERROR,
    payload: { error }
  }),
  saveApiError: (error) => ({
    type: actions.SAVE_API_ERROR,
    payload: { error }
  })
};

export default actions;
