import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';

function* loadFaq(payload) {
  try {
    yield put(actions.loadFaqApiSuccess(payload.pagination, payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadApiError(error));
  }
}

function* storeFaqApi() {
  try {
    yield put({ type: actions.LOAD_FAQ_LIST });
  } catch (error) {
    console.log(error);
    yield put(actions.saveApiError(error));
  }
}

function* loadFaqCategory(payload) {
  try {
    yield put(actions.loadFaqCategoryApiSuccess(payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadApiError(error));
  }
}

function* storeFaqCategoryApi() {
  try {
    yield put({ type: actions.LOAD_FAQCATEGORY_LIST });
  } catch (error) {
    console.log(error);
    yield put(actions.saveApiError(error));
  }
}

function* loadFaqCategoryList(payload) {
  try {
    yield put(actions.loadFaqCategoriesApiSuccess(payload.pagination, payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadApiError(error));
  }
}

function* storeFaqCategoryListApi() {
  try {
    yield put({ type: actions.LOAD_FAQ_CATEGORIES_LIST });
  } catch (error) {
    console.log(error);
    yield put(actions.saveApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FAQ_LIST, loadFaq),
    takeEvery(actions.SAVE_FAQ_LIST, storeFaqApi),

    takeEvery(actions.LOAD_FAQCATEGORY_LIST, loadFaqCategory),
    takeEvery(actions.SAVE_FAQCATEGORY_LIST, storeFaqCategoryApi),

    takeEvery(actions.LOAD_FAQ_CATEGORIES_LIST, loadFaqCategoryList),
    takeEvery(actions.SAVE_FAQ_CATEGORIES_LIST, storeFaqCategoryListApi)
  ]);
}
