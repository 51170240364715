import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';

function* loadFromApi(payload) {
  try {
    yield put(actions.loadFromApiSuccess(payload.pagination, payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadFromApiVoices(payload) {
  try {
    yield put(actions.loadFromApiVoicesSuccess(payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadFromApiSubs(payload) {
  try {
    yield put(actions.loadFromApiSubsSuccess(payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadFromApiPromptVari(payload) {
  try {
    yield put(actions.loadFromApiPromptVariSuccess(payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* storeIntoApi() {
  try {
    yield put({ type: actions.LOAD_FROM_API });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoAPIError(error));
  }
}

function* storeIntoApiVoices() {
  try {
    yield put({ type: actions.LOAD_FROM_API_VOICES });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoAPIError(error));
  }
}

function* storeIntoApiSubs() {
  try {
    yield put({ type: actions.LOAD_FROM_API_SUBS });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoAPIError(error));
  }
}

function* storeIntoApiPromptVari() {
  try {
    yield put({ type: actions.LOAD_FROM_API_PROMPT_VARI });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoAPIError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.SAVE_INTO_API, storeIntoApi),

    takeEvery(actions.LOAD_FROM_API_VOICES, loadFromApiVoices),
    takeEvery(actions.SAVE_INTO_API_VOICE, storeIntoApiVoices),

    takeEvery(actions.LOAD_FROM_API_SUBS, loadFromApiSubs),
    takeEvery(actions.SAVE_INTO_API_SUBS, storeIntoApiSubs),

    takeEvery(actions.LOAD_FROM_API_PROMPT_VARI, loadFromApiPromptVari),
    takeEvery(actions.SAVE_INTO_API_PROMPT_VARI, storeIntoApiPromptVari)
  ]);
}
