import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  subscriptionList: [],
  exerciseList: []
};

export default function subscriptionReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_SUBSCRIPTION_LIST:
      return {
        ...state,
        subscriptionList: action.data
      };
    case actions.LOAD_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        subscriptionList: action.payload.data,
        pagination: action.payload.pagination,
        errorMessage: false
      };
    case actions.LOAD_EXERCISE_LIST:
      return {
        ...state,
        exerciseList: action.data
      };
    case actions.LOAD_EXERCISE_LIST_SUCCESS:
      return {
        ...state,
        exerciseList: action.payload.data
      };
    case actions.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload.value
      };
    default:
      return state;
  }
}
