import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';

function* loadPromocodes(payload) {
  try {
    yield put(actions.loadPromocodesApiSuccess(payload.pagination, payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadModalityApiError(error));
  }
}

function* storePromocodesApi() {
  try {
    yield put({ type: actions.LOAD_PROMOCODES_LIST });
  } catch (error) {
    console.log(error);
    yield put(actions.saveModalityApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PROMOCODES_LIST, loadPromocodes),
    takeEvery(actions.SAVE_PROMOCODES_LIST, storePromocodesApi)
  ]);
}
