import { object, string } from 'yup';

const validationSchema = object({
  field: string().required(),
  value: string().required('Content is not allowed to empty')
});

export const initialValues = {
  field: '',
  value: ''
};
export default validationSchema;
