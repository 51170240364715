import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';

function* loadVideos(payload) {
  try {
    yield put(actions.loadHelpvideosApiSuccess(payload.pagination, payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadApiError(error));
  }
}

function* storeVideosApi() {
  try {
    yield put({ type: actions.LOAD_HELPVIDEOS_LIST });
  } catch (error) {
    console.log(error);
    yield put(actions.saveApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_HELPVIDEOS_LIST, loadVideos),
    takeEvery(actions.SAVE_HELPVIDEOS_LIST, storeVideosApi)
  ]);
}
