import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { token, user } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        user,
        profile: 'Profile'
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('token', payload.token);
    if (!localStorage.getItem('user')) {
      yield localStorage.setItem('user', JSON.stringify(payload.user));
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('token');
    const user = getToken().get('user');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        user,
        profile: 'Profile'
      });
    }
  });
}

function* loadModality(payload) {
  try {
    yield put(actions.loadModalityApiSuccess(payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadModalityApiError(error));
  }
}

function* storeModalityApi() {
  try {
    yield put({ type: actions.LOAD_MODALITY_DETAILS });
  } catch (error) {
    console.log(error);
    yield put(actions.saveModalityApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),

    takeEvery(actions.LOAD_MODALITY_DETAILS, loadModality),
    takeEvery(actions.SAVE_MODALITY_DETAILS, storeModalityApi)
  ]);
}
