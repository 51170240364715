const actions = {
  LOAD_FAQ_LIST: 'LOAD_FAQ_LIST',
  LOAD_FAQ_LIST_SUCCESS: 'LOAD_FAQ_LIST_SUCCESS',
  SAVE_FAQ_LIST: 'SAVE_FAQ_LIST',

  LOAD_FAQCATEGORY_LIST: 'LOAD_FAQCATEGORY_LIST',
  LOAD_FAQCATEGORY_LIST_SUCCESS: 'LOAD_FAQCATEGORY_LIST_SUCCESS',
  SAVE_FAQCATEGORY_LIST: 'SAVE_FAQCATEGORY_LIST',

  LOAD_FAQ_CATEGORIES_LIST: 'LOAD_FAQ_CATEGORIES_LIST',
  LOAD_FAQ_CATEGORIES_LIST_SUCCESS: 'LOAD_FAQ_CATEGORIES_LIST_SUCCESS',
  SAVE_FAQ_CATEGORIES_LIST: 'SAVE_FAQ_CATEGORIES_LIST',

  LOAD_API_ERROR: 'LOAD_API_ERROR',
  SAVE_API_ERROR: 'SAVE_API_ERROR',

  TOGGLE_LOADING: 'TOGGLE_LOADING',

  toggleLoading: (value) => ({ type: actions.TOGGLE_LOADING, payload: { value } }),

  loadFaqApi: (pagination, data) => {
    return { type: actions.LOAD_FAQ_LIST, pagination, data };
  },
  loadFaqApiSuccess: (pagination, data) => ({
    type: actions.LOAD_FAQ_LIST_SUCCESS,
    payload: { pagination, data }
  }),
  loadFaqCategory: (data) => {
    return { type: actions.LOAD_FAQCATEGORY_LIST, data };
  },
  loadFaqCategoryApiSuccess: (data) => ({
    type: actions.LOAD_FAQCATEGORY_LIST_SUCCESS,
    payload: { data }
  }),
  loadFaqCategoriesApi: (pagination, data) => {
    return { type: actions.LOAD_FAQ_CATEGORIES_LIST, pagination, data };
  },
  loadFaqCategoriesApiSuccess: (pagination, data) => ({
    type: actions.LOAD_FAQ_CATEGORIES_LIST_SUCCESS,
    payload: { pagination, data }
  }),
  loadApiError: (error) => ({
    type: actions.LOAD_API_ERROR,
    payload: { error }
  }),
  saveApiError: (error) => ({
    type: actions.SAVE_API_ERROR,
    payload: { error }
  })
};

export default actions;
