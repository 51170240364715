import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  helpVideoList: []
};

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_HELPVIDEOS_LIST:
      return {
        ...state,
        helpVideoList: action.data
      };
    case actions.LOAD_HELPVIDEOS_LIST_SUCCESS:
      return {
        ...state,
        helpVideoList: action.payload.data,
        pagination: action.payload.pagination,
        errorMessage: false
      };
    default:
      return state;
  }
}
