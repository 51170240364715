import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';

function* loadUser(payload) {
  try {
    yield put(actions.loadUserApiSuccess(payload.pagination, payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadApiError(error));
  }
}
function* storeUserApi() {
  try {
    yield put({ type: actions.LOAD_USER_LIST });
  } catch (error) {
    console.log(error);
    yield put(actions.saveApiError(error));
  }
}

function* loadUserExercise(payload) {
  try {
    yield put(actions.loadUserExerciseApiSuccess(payload.pagination, payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadApiError(error));
  }
}
function* storeUserExerciseApi() {
  try {
    yield put({ type: actions.LOAD_EXERCISE_LIST });
  } catch (error) {
    console.log(error);
    yield put(actions.saveApiError(error));
  }
}

function* loadProfession(payload) {
  try {
    yield put(actions.loadProfessionApiSuccess(payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadApiError(error));
  }
}
function* storeProfessionApi() {
  try {
    yield put({ type: actions.LOAD_PROFESSION_LIST });
  } catch (error) {
    console.log(error);
    yield put(actions.saveApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_USER_LIST, loadUser),
    takeEvery(actions.SAVE_USER_LIST, storeUserApi),

    takeEvery(actions.LOAD_PROFESSION_LIST, loadProfession),
    takeEvery(actions.SAVE_PROFESSION_LIST, storeProfessionApi),

    takeEvery(actions.LOAD_EXERCISE_LIST, loadUserExercise),
    takeEvery(actions.SAVE_EXERCISE_LIST, storeUserExerciseApi)
  ]);
}
