const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  UPDATE_USER_DETAIL: 'UPDATE_USER_DETAIL',

  LOAD_MODALITY_DETAILS: 'LOAD_MODALITY_DETAILS',
  LOAD_MODALITY_DETAILS_SUCCESS: 'LOAD_MODALITY_DETAILS_SUCCESS',
  SAVE_MODALITY_DETAILS: 'SAVE_MODALITY_DETAILS',

  SET_SELECTED_MODALITY: 'SET_SELECTED_MODALITY',
  SET_SELECTED_MODALITY_SUCCESS: 'SET_SELECTED_MODALITY_SUCCESS',
  SAVE_SELECTED_MODALITY: 'SAVE_SELECTED_MODALITY',

  LOAD_MODALITY_ERROR: 'LOAD_MODALITY_ERROR',
  SAVE_MODALITY_ERROR: 'SAVE_MODALITY_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (token, user) => ({
    type: actions.LOGIN_REQUEST,
    payload: { token, user }
  }),
  logout: () => ({
    type: actions.LOGOUT
  }),
  updateUserDetails: (user) => ({
    type: actions.UPDATE_USER_DETAIL,
    payload: { user }
  }),

  loadModalityApi: (data) => {
    return { type: actions.LOAD_MODALITY_DETAILS, data };
  },
  loadModalityApiSuccess: (data) => ({
    type: actions.LOAD_MODALITY_DETAILS_SUCCESS,
    payload: { data }
  }),

  setSelectedModality: (modality) => {
    return { type: actions.SET_SELECTED_MODALITY, modality };
  },
  setSelectedModalitySuccess: (modality) => ({
    type: actions.SET_SELECTED_MODALITY_SUCCESS,
    modality: { modality }
  }),

  loadModalityApiError: (error) => ({
    type: actions.LOAD_MODALITY_ERROR,
    payload: { error }
  }),
  saveModalityApiError: (error) => ({
    type: actions.SAVE_MODALITY_ERROR,
    payload: { error }
  })
};
export default actions;
