const DOCUMENT = 'EXERCISES_';

const actions = {
  LOAD_FROM_API: DOCUMENT + 'LOAD_FROM_API',
  LOAD_FROM_API_SUCCESS: DOCUMENT + 'LOAD_FROM_API_SUCCESS',
  LOAD_FROM_API_ERROR: DOCUMENT + 'LOAD_FROM_API_ERROR',

  SAVE_INTO_API: DOCUMENT + 'SAVE_INTO_API',
  SAVE_INTO_API_VOICE: DOCUMENT + 'SAVE_INTO_API_VOICE',
  SAVE_INTO_API_SUBS: DOCUMENT + 'SAVE_INTO_API_SUBS',
  SAVE_INTO_API_PROMPT_VARI: DOCUMENT + 'SAVE_INTO_API_PROMPT_VARI',

  SAVE_INTO_API_ERROR: DOCUMENT + 'SAVE_INTO_API_ERROR',

  RESET_API_DOCUMENTS_ERROR: DOCUMENT + 'RESET_API_DOCUMENTS_ERROR',

  TOGGLE_API_HANDLE_MODAL: DOCUMENT + 'TOGGLE_API_HANDLE_MODAL',
  API_UPDATE: DOCUMENT + 'API_UPDATE',

  TOGGLE_LOADING: DOCUMENT + 'TOGGLE_LOADING',
  // voices
  LOAD_FROM_API_VOICES: DOCUMENT + 'LOAD_FROM_API_VOICES',
  LOAD_FROM_API_VOICES_SUCESS: DOCUMENT + 'LOAD_FROM_API_VOICES_SUCESS',
  // subscriptions
  LOAD_FROM_API_SUBS: DOCUMENT + 'LOAD_FROM_API_SUBS',
  LOAD_FROM_API_SUBS_SUCESS: DOCUMENT + 'LOAD_FROM_API_SUBS_SUCESS',
  // prompt variables
  LOAD_FROM_API_PROMPT_VARI: DOCUMENT + 'LOAD_FROM_API_PROMPT_VARI',
  LOAD_FROM_API_PROMPT_VARI_SUCESS: DOCUMENT + 'LOAD_FROM_API_PROMPT_VARI_SUCESS',

  toggleLoading: (value) => ({ type: actions.TOGGLE_LOADING, payload: { value } }),

  loadFromApi: (pagination, data) => {
    return { type: actions.LOAD_FROM_API, pagination, data };
  },

  loadFromApiSuccess: (pagination, data) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: { pagination, data }
  }),

  loadFromApiVoices: (data) => {
    return { type: actions.LOAD_FROM_API_VOICES, data };
  },

  loadFromApiVoicesSuccess: (data) => ({
    type: actions.LOAD_FROM_API_VOICES_SUCESS,
    payload: { data }
  }),

  loadFromApiSubs: (data) => {
    return { type: actions.LOAD_FROM_API_SUBS, data };
  },

  loadFromApiSubsSuccess: (data) => ({
    type: actions.LOAD_FROM_API_SUBS_SUCESS,
    payload: { data }
  }),

  loadFromApiPromptVari: (data) => {
    return { type: actions.LOAD_FROM_API_PROMPT_VARI, data };
  },

  loadFromApiPromptVariSuccess: (data) => ({
    type: actions.LOAD_FROM_API_PROMPT_VARI_SUCESS,
    payload: { data }
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: { error }
  }),

  saveIntoApi: (data, actionName = 'insert') => ({
    type: actions.SAVE_INTO_API,
    payload: { data, actionName }
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_API_HANDLE_MODAL,
    payload: { data }
  }),

  update: (data) => ({
    type: actions.API_UPDATE,
    payload: { data }
  }),

  saveIntoAPIError: (error) => ({
    type: actions.SAVE_INTO_API_ERROR,
    payload: { error }
  })
};
export default actions;
