import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  promocodeList: []
};

export default function promocodeReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_PROMOCODES_LIST:
      return {
        ...state,
        promocodeList: action.data
      };
    case actions.LOAD_PROMOCODES_LIST_SUCCESS:
      return {
        ...state,
        promocodeList: action.payload.data,
        pagination: action.payload.pagination,
        errorMessage: false
      };
    default:
      return state;
  }
}
