import actions from './actions';

const initState = {
  token: null,
  user: null,
  modalities: null,
  selectedModality: process.env.REACT_APP_DEFAULT_MODALITY
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        user: action.user
      };
    case actions.LOGOUT:
      return initState;
    case actions.UPDATE_USER_DETAIL:
      return {
        ...state,
        user: action.payload.user
      };
    case actions.LOAD_MODALITY_DETAILS:
      return {
        ...state,
        modalities: action.data
      };
    case actions.LOAD_MODALITY_DETAILS_SUCCESS:
      return {
        ...state,
        modalities: action.payload.data
      };
    case actions.SET_SELECTED_MODALITY:
      return {
        ...state,
        selectedModality: action.modality
      };

    default:
      return state;
  }
}
