import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  faqList: [],
  categoryList: [],
  categories: []
};

export default function faqReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_FAQ_LIST:
      return {
        ...state,
        faqList: action.data
      };
    case actions.LOAD_FAQ_LIST_SUCCESS:
      return {
        ...state,
        faqList: action.payload.data,
        pagination: action.payload.pagination,
        errorMessage: false
      };
    case actions.LOAD_FAQCATEGORY_LIST:
      return {
        ...state,
        categories: action.data
      };
    case actions.LOAD_FAQCATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categories: action.payload.data,
        errorMessage: false
      };
    case actions.LOAD_FAQ_CATEGORIES_LIST:
      return {
        ...state,
        categoryList: action.data
      };
    case actions.LOAD_FAQ_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        categoryList: action.payload.data,
        pagination: action.payload.pagination,
        errorMessage: false
      };
    case actions.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload.value
      };
    default:
      return state;
  }
}
