import actions from './actions';
const initState = {
  isLoading: false,
  errorMessage: false,
  exercises: [],
  modalActive: false,
  exercise: {},
  voices: [],
  subscriptions: [],
  promptVaries: []
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        errorMessage: false,
        modalActive: false
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        exercises: payload.data,
        pagination: payload.pagination,
        errorMessage: false
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        errorMessage: 'There is a loading problem'
      };
    case actions.TOGGLE_API_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        exercises: payload.data || initState.exercises
      };
    case actions.API_UPDATE:
      return {
        ...state,
        domina: payload.data
      };
    case actions.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    case actions.LOAD_FROM_API_VOICES:
      return {
        ...state
      };
    case actions.LOAD_FROM_API_VOICES_SUCESS:
      return {
        ...state,
        voices: payload.data
      };
    case actions.LOAD_FROM_API_SUBS:
      return {
        ...state
      };
    case actions.LOAD_FROM_API_SUBS_SUCESS:
      return {
        ...state,
        subscriptions: payload.data
      };
    case actions.LOAD_FROM_API_PROMPT_VARI:
      return {
        ...state
      };
    case actions.LOAD_FROM_API_PROMPT_VARI_SUCESS:
      return {
        ...state,
        promptVaries: payload.data
      };
    default:
      return state;
  }
}
