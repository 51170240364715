const actions = {
  LOAD_PROMOCODES_LIST: 'LOAD_PROMOCODES_LIST',
  LOAD_PROMOCODES_LIST_SUCCESS: 'LOAD_PROMOCODES_LIST_SUCCESS',
  SAVE_PROMOCODES_LIST: 'SAVE_PROMOCODES_LIST',

  loadPromocodesApi: (pagination, data) => {
    return { type: actions.LOAD_PROMOCODES_LIST, pagination, data };
  },
  loadPromocodesApiSuccess: (pagination, data) => ({
    type: actions.LOAD_PROMOCODES_LIST_SUCCESS,
    payload: { pagination, data }
  })
};

export default actions;
