export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  CHECK_MAIL: '/checkmail',
  RESET_PASSWORD: '/resetpassword/:token',
  PAGE_404: '/404',
  PAGE_500: '/500'
};

export const PRIVATE_ROUTE = {
  DASHBOARD: '/',
  PROFILE: '/my-profile'
};
